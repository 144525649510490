import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const Text = tw.p`max-w-none mt-5 max-w-xl mx-auto text-center flex flex-col`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-4 md:pr-4 lg:pr-4`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 min-h-[160px]`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  id = '',
  heading = "Checkout the Menu",
  tabs = {
    Starters: [
      { title: "Pestoricco", price: "", content: "Mascarponesaus met pesto van zongedroogde tomaten en rucola" },
      { title: "Carbonara", price: "", content: "Roomsaus met Parmesan en ham" },
      { title: "Cheesy Bacon", price: "", content: "Kaassaus met stukjes tomaat en spek" },
      { title: "Bolognaise", price: "", content: "Traditionele tomatensaus met gemengd gehakt" },
      { title: "4 Formaggi", price: "", content: "Roomsaus met Gorgonzola, Taleggio, Fontal en Mozarella" },
      { title: "Giovanni", price: "", content: "Roomsaus met kip en champignons" },
      { title: "Maison", price: "", content: "Bolognaisesaus met oregano, basilicum en look" },
      { title: "Arrabiata", price: "", content: "Pikante tomatensaus met champignons, paprika en erwtjes" },
      { title: "Romina", price: "", content: "Pittige gehaktsaus met ajuin en sambal" },
      { title: "Al Salmone", price: "", content: "Roomsaus met gerookte zalm" }
    ],
    Pizzas: [
      { title: "Margherita", price: "€8", content: "" },
      { title: "Bolognaise", price: "€8", content: "" },
      { title: "Champignons", price: "€8", content: "" },
      { title: "Ananas", price: "€8", content: "" },
      { title: "Ham", price: "€8", content: "" },
      { title: "Gorgonzola", price: "€9", content: "" },
      { title: "Salami", price: "€9", content: "" },
      { title: "Ham & Ananas", price: "€9", content: "" },
      { title: "Tonijn & Kappers", price: "€9", content: "" },
      { title: "Parmaham", price: "€9", content: "" },
      { title: "Pikante Salami", price: "€9", content: "" },
      { title: "Ham, Ananas & Champignons", price: "€9", content: "" },
      { title: "Vegetarisch", price: "€9", content: "" },
      { title: "Italiaanse Ham & Ananas", price: "€9", content: "" },
      { title: "Pikante Salami & Ansjovis", price: "€9", content: "" },
      { title: "Ham & Champignons", price: "€9", content: "" }
    ],
    // Panini: [
    //   { title: "Panini Parma", price: "", content: "" },
    //   { title: "Panini Salami-Paprika", price: "", content: "" },
    //   { title: "Panini Bolognaise", price: "", content: "" },
    //   { title: "Panini Mozzarella-Tomaat", price: "", content: "" },
    //   { title: "Panini Kip-Boursin", price: "", content: "" }
    // ],
    Focaccia: [
      // { title: "Broodje Wurztel", price: "", content: "" },
      { title: "Focaccia Kaas Kruiden", price: "", content: "" },
      { title: "Focaccia Kaas Tomaat", price: "", content: "" }
    ],
    Desserts: [
      {
        "title": "Tiramisu",
        "price": "",
        "content": ""
      },
      {
        "title": "Chocomousse",
        "price": "",
        "content": ""
      },
      {
        "title": "Panna Cotta",
        "price": "",
        "content": ""
      },
      {
        "title": "Tiramisu speculoos",
        "price": "",
        "content": ""
      }
    ],
    Dranken: [
      {
        "title": "Montepulciano Rood 25 cl",
        "price": "",
        "content": ""
      },
      {
        "title": "Trebbiano Wit 25 cl",
        "price": "",
        "content": ""
      },
      {
        "title": "Jupiler Blik 33 cl",
        "price": "",
        "content": ""
      },
      {
        "title": "Plat Water",
        "price": "",
        "content": ""
      },
      {
        "title": "Ice Tea Lipton pet",
        "price": "",
        "content": ""
      },
      {
        "title": "Ice Tea Lipton Green pet",
        "price": "",
        "content": ""
      },
      {
        "title": "Spite pet",
        "price": "",
        "content": ""
      },
      {
        "title": "Bruis Water",
        "price": "",
        "content": ""
      },
      // {
      //   "title": "Minute Maid Appel pet",
      //   "price": "",
      //   "content": ""
      // },
      {
        "title": "Pepsi Max pet",
        "price": "",
        "content": ""
      },
      {
        "title": "Pepsi pet",
        "price": "",
        "content": ""
      },
      {
        "title": "Pepsi max lemon blik",
        "price": "",
        "content": ""
      },
      {
        "title": "Pepsi max cherry blik",
        "price": "",
        "content": ""
      },
      {
        "title": "Pepsi max blik",
        "price": "",
        "content": ""
      },
      {
        "title": "Pepsi blik",
        "price": "",
        "content": ""
      },
      {
        "title": "Fanta Sinaas pet",
        "price": "",
        "content": ""
      },
      // {
      //   "title": "Fanta Citroen pet",
      //   "price": "",
      //   "content": ""
      // },
      // {
      //   "title": "Minute Maid Sinaas pet",
      //   "price": "",
      //   "content": ""
      // }
    ]
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container id={id}>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <Text><span>Pasta - Klein: €5,50 | Middel: €6,50 | Maxi: €7,50</span><span><strong>Extra saus</strong>: €0,95 | Extra kaas: €0,95 : <strong>Vegan Bolognaisesaus</strong>: €1</span></Text>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
